import { AuthService } from '../services/authservice.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
declare var Fingerprint2: any

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = this.auth.getJWT();
    const isAgent = this.auth.isAdmin() ? 'false' : 'true';
    console.log(isAgent);
    Fingerprint2.getV18({}, function (result, components) {
      localStorage.setItem('print',result)
    })
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      headers: req.headers.set('Authorization', 'jwt ' + authToken)
        .set('account-id', (localStorage.getItem('account_id')) ? (localStorage.getItem('account_id')) : '')
        .set('is-agent', isAgent)
        .set('originid', "admin")
        .set('finger-print',localStorage.getItem('print'))
    });
    // send cloned request with header to the next handler.
    return next.handle(authReq);
  }
}
