import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { configuration } from './../../../config';
const authKey = 'secretkey';

export interface Person {
    _id: string;
    name: string;
}
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(public http: HttpClient) {}

    // get method
    public _get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.get(`${configuration.baseUrl}/${path}`, { params, headers: { webToken: authKey } }).pipe(
            map(response => response),
            catchError(error => of(error))
        );
    }

    // get countries method
    public _getCountries( ): Observable<any> {
        return this.http.get(`${configuration.countryUrl}`).pipe(
            map(response => response),
            catchError(error => of(error))
        );
    }

    // put method
    public _put(path: string, body: Object = {}): Observable<any> {
        return this.http.put(`${configuration.baseUrl}/${path}`, body, { headers: { webToken: authKey } }).pipe(
            map(response => response),
            catchError(error => of(error))
        );
    }

    // post method
    public _post(path: string, body: Object = {}): Observable<any> {
        return this.http.post(`${configuration.baseUrl}/${path}`, body, { headers: { webToken: authKey } }).pipe(
            map(response => response),
            catchError(error => of(error))
        );
    }

    // delete method
    public _delete(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        return this.http.delete(`${configuration.baseUrl}/${path}`, { params, headers: { webToken: authKey } }).pipe(
            map(response => response),
            catchError(error => of(error))
        );
    }
}
