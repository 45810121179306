import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    title: true,
    name: 'Navigation'
  },
  {
    name: 'Statistics',
    url: '/pages/statistics',
    icon: 'icon-speedometer'
  },
  {
    name: 'Masters',
    url: '/buttons',
    icon: 'icon-layers',
    children: [
      {
        name: 'Accounts',
        url: '/pages/accounts',
        icon: 'icon-puzzle'
      },
      {
        name: 'Groups',
        url: '/pages/groups',
        icon: 'icon-puzzle'
      },
      {
        name: 'Skills',
        url: '/pages/skills',
        icon: 'icon-puzzle'
      },
      {
        name: 'Disposition',
        url: '/pages/add-disposition',
        icon: 'icon-puzzle'
      }
    ]
  },
  {
    name: 'EDA Ads',
    url: '/pages/eda-ads',
    icon: 'icon-credit-card'
  },
  {
    name: 'Ads Response',
    url: '/pages/ad-response',
    icon: 'icon-credit-card'
  },
  {
    name: 'Monthly Report',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Today Report',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Forecast Report',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Volume Report',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Disposition Summary',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Hourly Disposition Trend',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Report',
    url: '/pages/report',
    icon: 'icon-calendar'
  },
  {
    name: 'Agent Management',
    url: '/pages/agent-management',
    icon: 'icon-calendar'
  },
  {
    name: 'User Management',
    url: '/pages/user-management',
    icon: 'icon-calendar'
  },
  {
    name: 'AI Services',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'NLC Services',
    url: '/theme/colors',
    icon: 'icon-calendar'
  },
  {
    name: 'Thread Close',
    url: '/pages/thread-close',
    icon: 'icon-calendar'
  }, {
    name: 'Shift Schedule',
    url: '/pages/schedule-chat',
    icon: 'icon-calendar'
  }, {
    name: 'Canned Messages',
    url: '/pages/canned-messages',
    icon: 'icon-calendar'
  }, {
    name: 'Payment',
    url: '/pages/payment',
    icon: 'icon-calendar'
  }, {
    name: 'Supplier Types',
    url: '/theme/colors',
    icon: 'icon-calendar'
  }, {
    name: 'Suppliers',
    url: '/theme/colors',
    icon: 'icon-calendar'
  }, {
    name: 'Suggestions',
    url: '/theme/colors',
    icon: 'icon-calendar'
  }
];
