import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(
    private socket: Socket
  ) {}
  getLiveLabel() {
    return this.socket
        .fromEvent<any>('live_label')
        .pipe(map(res => res));
  }
}
