import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { configuration } from './../../../config';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    constructor(private http: HttpClient) { }

    public login(data): Observable<any> {
        return this.http.post(`${configuration.baseUrl}/user/loginAsAdmin`, data).pipe(
            map(response => response),
            catchError(error => of(error))
        );
    }

    public isAuthenticated(): boolean {
        const token = localStorage.getItem('access_token');
        if (token) {
            return true;
        }
        return false;
    }

    public getJWT() {
        return localStorage.getItem('access_token');
    }

    public getUserId() {
        return localStorage.getItem('user_id');
    }

    public getRole() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.role;
    }

    public isAdmin() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            return user.role === 'Admin';
        } else {
            return false;
        }
    }

    public getUser() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user;
    }
}
