import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '../services/authservice.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canActivate(): boolean {
    if (!this.authenticationService.isAuthenticated()) {
      console.log('Not Authenticated');
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
