import { Component } from '@angular/core';
import { navItems } from '../../_nav';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { ApiService } from "../../services/crudapi.services";
import { Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { MessageService } from "../../services/message.service";
import { Subscription } from 'rxjs';
import { AuthService } from '../../services/authservice.service';


@Component({
    selector: 'app-dashboard',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent {
    public sidebarMinimized = false;
    public navItems = navItems;
    public liveLable : any = {}
    subscription: Subscription;
    constructor(
      private router: Router,
      private socket: Socket ,
      private apiService: ApiService, private messageService: MessageService, private auth: AuthService) {
        this.socket.ioSocket.io.disconnect()
        // if(localStorage.getItem('account_id')&&localStorage.getItem('account_id')!=''){
        //     this.navItems[2].children.splice(0,1)
        //     this.navItems.splice(13,1)
        // }else{
            this.navItems = [
                {
                  title: true,
                  name: 'Navigation'
                },
                {
                  name: 'Statistics',
                  url: '/pages/statistics',
                  icon: 'icon-speedometer'
                },
                {
                  name: 'Masters',
                  url: '/buttons',
                  icon: 'icon-layers',
                  children: [
                    {
                      name: 'Accounts',
                      url: '/pages/accounts',
                      icon: 'icon-puzzle',
                    },
                    {
                      name: 'Groups',
                      url: '/pages/groups',
                      icon: 'icon-puzzle'
                    },
                    {
                      name: 'Skills',
                      url: '/pages/skills',
                      icon: 'icon-puzzle'
                    },
                    {
                      name: 'Disposition',
                      url: '/pages/add-disposition',
                      icon: 'icon-puzzle'
                    },
                    {
                      name: 'Menu',
                      url: '/pages/add-menu',
                      icon: 'icon-puzzle'
                    },
                    {
                      name: 'Roles',
                      url: '/pages/roles',
                      icon: 'icon-puzzle'
                    }
                  ]
                },
                {
                  name: 'EDA Ads',
                  url: '/pages/eda-ads',
                  icon: 'icon-credit-card'
                },
                {
                  name: 'Ads Response',
                  url: '/pages/ad-response',
                  icon: 'icon-credit-card'
                },
                {
                  name: 'Monthly Report',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Today Report',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Forecast Report',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Volume Report',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Disposition Summary',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Hourly Disposition Trend',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Report',
                  url: '/pages/report',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Agent Management',
                  url: '/pages/agent-management',
                  icon: 'icon-calendar'
                },
                {
                  name: 'User Management',
                  url: '/pages/user-management',
                  icon: 'icon-calendar'
                },
                {
                  name: 'AI Services',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'NLC Services',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                },
                {
                  name: 'Thread Close',
                  url: '/pages/thread-close',
                  icon: 'icon-calendar'
                }, {
                  name: 'Shift Schedule',
                  url: '/pages/schedule-chat',
                  icon: 'icon-calendar'
                }, {
                  name: 'Canned Messages',
                  url: '/pages/canned-messages',
                  icon: 'icon-calendar'
                }, {
                  name: 'Payment',
                  url: '/pages/payment',
                  icon: 'icon-calendar'
                }, {
                  name: 'Supplier Types',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                }, {
                  name: 'Suppliers',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                }, {
                  name: 'Suggestions',
                  url: '/theme/colors',
                  icon: 'icon-calendar'
                }
              ];
        // }
        this.socket.ioSocket.io.opts.query = { token: localStorage.getItem('user_id') }
        this.socket.ioSocket.io.connect()
        this.getLiveLable()
        this.subscription = this.messageService.getMessage().subscribe(data => {
            if(data.data == 'live_label') this.getLiveLable()
        })
    }

    async getLiveLable (){
      const user_id = this.auth.getUserId();
        let live_lable = await this.apiService._get('accounts/labels/LiveLabel?user_id='+user_id).toPromise()
        console.log(this.liveLable)
        this.liveLable = live_lable.response
    }

    toggleMinimize(e) {
        this.sidebarMinimized = e;
    }
    logout() {
        this.router.navigateByUrl('/');
        localStorage.removeItem('access_token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('user');
        localStorage.clear();
    }
}
