import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SocketService } from './services/socket.service';
import { MessageService } from "./services/message.service";

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router,private socketService : SocketService,private message : MessageService) { }

  ngOnInit() {
    this.socketService
    .getLiveLabel()
    .subscribe(msg => {
      this.message.sendMessage({data : "live_label"});
    })
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
