import { Component, OnInit, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/authservice.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    submitted = false;
    loading = false;
    error = '';
    fieldTextType: boolean;
    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private zone: NgZone
    ) {
        this.loginForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    ngOnInit() { }

    get f() {
        return this.loginForm.controls;
    }
    toggleFieldTextType() {
        this.fieldTextType = !this.fieldTextType;
    }

    login() {
        this.submitted = true;
        const val = this.loginForm.value;
        if (val.email && val.password) {
            this.loading = true;
            let obj = {
                email: val.email,
                password: val.password
            };
            this.authService.login(obj).subscribe(
                res => {
                    console.log(res);
                    if (res.response) {
                        localStorage.setItem('access_token', res.response.user.access_token);
                        localStorage.setItem('account_id', res.response.user.account_id);
                        localStorage.setItem('account_name', res.response.user.account_name);
                        localStorage.setItem('user_id', res.response.user._id);
                        localStorage.setItem('user', JSON.stringify(res.response.user));
                        this.zone.run(() => {
                            this.router.navigate(['pages/agent-management']).then(() => { });
                        });
                    } else {
                        if (res.error.message) {
                            this.error = res.error.message;
                        }
                        if (res.error.error) {
                            this.error = res.error.error;
                        }
                        this.loading = false;
                    }
                },
                error => {
                    this.error = error;
                    this.loading = false;
                }
            );
        }
    }
}
